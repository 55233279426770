import React from "react"
import PropTypes from "prop-types"

function SocialMediaLink({ url, imgSrc, altText }) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img src={imgSrc} alt={altText} className="social-media-icon" />
    </a>
  )
}

SocialMediaLink.propTypes = {
  url: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
}

export default SocialMediaLink
