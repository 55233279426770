import React, { useState } from "react"
import "../styles/quotegallery.css"
import "../styles/App.css"

const QuoteGallery = () => {
  const quotes = [
    "They do a fabulous job! We've used them for years and we're always happy. --Carrie S.",
    "Mike and his Son were on time, efficient and very detail oriented. Windows have never looked better! Prices are more than fair! --Chris J.",
    "Mike and his crew did SUCH a great job on our very hard-water-stained windows! It's like there's no glass now, so clear! (except where the doggo has re-done some nose prints....). I will definitely recommend Mike, and remember who to call! --Lisa G.",
    "Mike and his team do an amazing job! We've had him back every year for the past 5 years. Super hard working team. If you can squeeze in his busy schedule you won't be disappointed!  --Alison D.",
  ]

  const [selectedQuoteIndex, setSelectedQuoteIndex] = useState(0)

  const navigate = (direction) => {
    const newIndex =
      (selectedQuoteIndex + direction + quotes.length) % quotes.length
    setSelectedQuoteIndex(newIndex)
  }

  return (
    <div>
      <div className="button-div">
        <button className="nav-button" onClick={() => navigate(-1)}>
          {'<'}
        </button>
        <h2 className="button-h2">Testimonials</h2>
        <button className="nav-button" onClick={() => navigate(1)}>
          {'>'}
        </button>
      </div>
      
      <div className="quote-container">
        {quotes.map((quote, index) => (
          <div
            key={index}
            className={`quote-card pic ${
              index === selectedQuoteIndex ? "card-visible" : "card-hidden"
            }`}>
            <p className="quote">{quote}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default QuoteGallery;