import React, { useEffect } from "react"
import "../styles/calendly.css"

const CalendlyEmbed = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true
    document.body.appendChild(script)

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/mikeswindowcleaningutah/appointment?hide_gdpr_banner=1"
        style={{ minWidth: "320px", height: "700px" }}></div>
    </div>
  )
}

export default CalendlyEmbed




