import "../styles/App.css"
import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import About from "../pages/About"
import Schedule from "../pages/Schedule"
import Services from "../pages/Services"
import Layout from "./Layout"


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/schedule" element={<Schedule />} />
        </Routes>
      </Layout>
    </Router>
  )
}

export default App
