import React from "react"
import { useSpring, animated } from "react-spring"
import "../styles/navbar.css"
import logo from "../assets/banner-transparent-padding.png"
import logo2 from "../assets/banner-transparent-narrow.png"
import { Link } from "react-router-dom"

const Navbar = ({ isOpen, setIsOpen }) => {
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const logoAnimation = useSpring({
    from: { transform: "translateX(-100%)" },
    to: { transform: "translateX(0%)" },
    config: { tension: 220, friction: 120 },
  })

  return (
    <div>
      <nav className="navbar">
        <Link to="/" className="logo-link" onClick={closeMenu}>
          <animated.img
            src={logo}
            alt="Company Logo"
            style={logoAnimation}
            className="logo logo-desktop"
            width="290"
            height="105"
          />
          <animated.img
            src={logo2}
            alt="Company Logo"
            style={logoAnimation}
            className="logo logo-mobile"
            width="213"
            height="105"
          />
        </Link>
        <div className="menu-icon" onClick={toggleMenu}>
          &#9776; {/* Unicode character for hamburger menu */}
        </div>
        <ul className={`menu ${isOpen ? "open" : ""}`}>
          <li>
            <Link to="/" onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={closeMenu}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/schedule" onClick={closeMenu}>
              Schedule
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navbar
