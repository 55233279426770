import React from "react"

const ContactUs = () => {
  return (
    <div id="contact-us">
      <h2>Contact Us</h2>
      <p>Call or text: 801-949-7583</p>
      <p>Mon-Fri, 8am-6pm</p>
    </div>
  )
}

export default ContactUs
