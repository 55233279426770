import React from "react"
import Hero from "../components/Hero"
import QuoteGallery from "../components/QuoteGallery"
import mikeImage from "../assets/mike_5_2024.png"
import familyImage from "../assets/family.png"

import "../styles/about.css"
import "../styles/App.css"

function About() {
  
  return (
    <div>
      <Hero title="About us" />
      <div className="grid-container">
        <div className="grid-item">
          <p className="intro">
            Hi, I'm Mike. I started my career being the team lead over the
            windows at Temple Square. But soon, friends and family wanted my
            expertise to keep their home windows sparkling. In 2014, Mike's
            Window Cleaning Utah opened, serving Davis & Weber counties and
            North SLC. You may see one of my kids come and help me. Mason,
            Haiden, and my newest helper Lily. <br />
            <br />
            We have the same great service at the same price (no inflation
            here)! We hope to see you soon!
          </p>
        </div>
        <div className="grid-item grid-item-big">
          <img
            src={mikeImage}
            alt="Mike cleaning a window, May 2024"
            className="pic"
            width="620"
            height="621"
          />
        </div>
        <div className="grid-item family">
          <img
            src={familyImage}
            alt="Four smiling people"
            className="pic"
            width="671"
            height="416"
          />
        </div>
      </div>
      <div className="testimonials">
        <QuoteGallery />
      </div>
    </div>
  )
}

export default About
