import React from "react"
import Hero from "../components/Hero"
import "../styles/services.css"
import { Link } from "react-router-dom"



function Services() {
  return (
    <div>
      <Hero title="Services" />

      <h2>
        Residential Window Cleaning
        <br />
        We service Weber and Davis counties and North Salt Lake
      </h2>

      <div className="grid-container2">
        <div className="grid-item2">
          <h3>Full Service - $200</h3>
          <ul>
            <li>inside and outside windows</li>
            <li>screens</li>
            <li>tracks</li>
            <li>hard water removal</li>
          </ul>
          <Link to="/schedule">
            <button className="nav-button">Schedule</button>
          </Link>
        </div>
        <div className="grid-item2">
          <h3>Outside Only - $150</h3>
          <ul>
            <li>outside windows only</li>
            <li>screens</li>
            <li>tracks</li>
            <li>hard water removal</li>
          </ul>
          <Link to="/schedule">
            <button className="nav-button">Schedule</button>
          </Link>
        </div>
        <div className="grid-item2 grid-item-wide2">
          <h3>Large home?</h3>
          <p>
            If you have a larger house than the standard home size, reach out
            for a bid.
          </p>

          <p>Call or text: 801-949-7583</p>
          <p>Mon-Fri, 8am-6pm</p>
        </div>
      </div>
    </div>
  )
}

export default Services
