import React from "react"
import "../styles/footer.css"
import SocialMediaLink from "./SocialMediaLink"
import facebookLogo from "../assets/facebook_circle.png"
import instagramLogo from "../assets/instagram_circle.png"
import { Link } from "react-router-dom"

function Footer() {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <div className="footer-section">
        <p>&copy; {currentYear} Mike's Window Cleaning Utah</p>
      </div>
      <div className="footer-section contact">
        <Link to="/schedule#contact-us">Contact Us</Link>
      </div>
      <div className="footer-section social-media">
        <SocialMediaLink
          url="https://www.facebook.com/mikeswindowcleaningutah"
          imgSrc={facebookLogo}
          altText="Facebook logo"
        />
        <SocialMediaLink
          url="https://www.instagram.com/mikes_window_cleaning_utah/"
          imgSrc={instagramLogo}
          altText="Instagram logo"
        />
      </div>
    </footer>
  )
}

export default Footer
