import React, { useEffect } from "react"
import Hero from "../components/Hero"
import CalendlyEmbed from "../components/CalendlyEmbed"
import ContactUs from "../components/ContactUs"
import "../styles/schedule.css"

function Schedule() {
  useEffect(() => {
    // Scroll to ContactUs section only if the hash is '#contact-us'
    if (window.location.hash === "#contact-us") {
      const contactUsSection = document.getElementById("contact-us")
      if (contactUsSection) {
        contactUsSection.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  return (
    <div className="schedule-page">
      <Hero title="Schedule a Window Cleaning" />
      <CalendlyEmbed />
      <div id="contact-us">
        <ContactUs />
      </div>
    </div>
  )
}

export default Schedule
