import React, { useState } from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "../styles/layout.css"

function Layout({ children }) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`layout ${isOpen ? "menu-open" : ""}`}>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <main className="content">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
