import React from "react"
import Hero from "../components/Hero"
import "../styles/home.css"
import flowersImage from "../assets/flowers250.jpg"
import patioImage from "../assets/patio250.jpg"
import ragImage from "../assets/cleaning_rag_blue250.png"
import squeegeeImage from "../assets/squeegee_blue250.png"
import { Link } from "react-router-dom"


function Home() {
  return (
    <div>
      <Hero title="Mike's Window Cleaning Utah" />
      <h2>Keeping your windows clean since 2014</h2>
      <div>
        <Link to="/schedule">
          <div className="grid-container-home">
            <div className="grid-item-home">
              <img
                src={flowersImage}
                alt="orange flowers in front of a clean window"
                className="pic"
                width="250"
                height="250"
              />
            </div>

            <div className="grid-item-home">
              <img
                src={squeegeeImage}
                alt="squeegee cleaning a window"
                className="pic"
                width="250"
                height="250"
              />
            </div>

            <div className="grid-item-home extra-pic">
              <img
                src={ragImage}
                alt="hand cleaning window with a rag"
                className="pic"
                width="250"
                height="250"
              />
            </div>

            <div className="grid-item-home extra-pic">
              <img
                src={patioImage}
                alt="sliding door opens to patio"
                className="pic"
                width="250"
                height="250"
              />
            </div>
          </div>
        </Link>
      </div>

      <div className="responsive-container">
        <div className="responsive-item">
          <p>
            We service the Davis and Weber counties as well as North Salt Lake.
            Check out our flat-rate services.
          </p>
          <Link to="/services">
            <button className="nav-button">Services</button>
          </Link>
        </div>

        <div className="responsive-item">
          <p>
            Our calendar fills up pretty quick. Make an appointment to get your
            windows clean!
          </p>
          <Link to="/schedule">
            <button className="nav-button">Schedule</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home
